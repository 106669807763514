import React from 'react';
import styled from 'styled-components';
import palette from './styles/palette';
import hamin from './img/hamin.jpg'
import img1 from './img/jang.png'
import img2 from './img/장민호.jpeg'
import img3 from './img/송가인.jpeg'
import img4 from './img/bulhu.jpeg'
import img5 from './img/scatch.jpeg'
import img6 from './img/김현철콘서트.jpeg'
import img7 from './img/cats.jpeg'
import img8 from './img/cats.png'
import img9 from './img/저스틴.jpeg'
import img10 from './img/mis.jpeg'
import img11 from './img/박경.jpeg'
import logo1 from './img/서울대.jpeg'
import logo2 from './img/서울예대.jpeg'
import logo4 from './img/웨일즈혼.jpeg'


const Div = styled.div`
/* 미디어 쿼리: 화면 가로폭이 768px 미만일 때 */
@media screen and (max-width: 768px) {
    width:90vw;
    padding:50px 0;
    #main{
        font-size:15px;
        width:70vw;
        margin:0 auto;
    }
    #hamin{
        margin:20px 0;
        width:90vw;
    }
    .profile{
        width:200px;
        border-radius:20px;
        -webkit-user-drag:none;
    }
    .logoBox{
        opacity:0.7;
        display:flex;
        justify-content: center;
        margin-bottom:50px;
      img{
        width:100px;
        height:100px;
        margin: 0 5px;
        border-radius:30%;
        -webkit-user-drag:none;
      } 
    }
    .profileBox{
        display:flex;
        flex-direction:column;
        justify-content: space-evenly;
        img{
            margin-top:30px;
        }
    }
}

/* 미디어 쿼리: 화면 가로폭이 768px 이상일 때 */
@media screen and (min-width: 768px) {
    width:700px;
    padding:50px 0;
    #hamin{
        margin:20px 0;
        width:700px;
        -webkit-user-drag:none;
    }
    .profile{
        width: 200px;
        border-radius:20px;
        -webkit-user-drag:none;
    }
    .logoBox{
        opacity:0.7;
        display:flex;
        justify-content: center;
        margin-bottom:50px;
      img{
   
        margin: 0 20px;
        border-radius:30%;
        -webkit-user-drag:none;
      } 
    }
    .profileBox{
        display:flex;
        justify-content: space-evenly;
        margin: 50px auto;
    }
}
   
    margin:0 auto;
    margin-bottom:50px;

    text-align:center;
    line-height:2;
    background-color:#f0f0f0;
    border-radius:20px;
    h3{
        margin-top: 50px;
        margin-bottom:10px;
    }
   
  
    .profileBox{
        display:flex;
        justify-content: space-evenly;
        margin: 50px auto;
    }

    .logo{
        width:180px;
        height:180px;
        
    }
   
`
const Director = () => {
    return (
        <Div>
            <div className='logoBox'>
          
                    <img className='logo' src={logo1} alt='logo'/>
                    <img  className='logo' src={logo2} alt='logo'/>
                    <img  className='logo' src={logo4} alt='logo'/>
            </div>
            <div id='main'>
                안녕하세요. 유튜브 '웨일즈혼'을 운영하는 
                <br/>색소포니스트 이하민입니다.<br/><br/>
                저는 서울대 콩쿠르에서 수상하였으며,
                <br/> 서울예술대학교를 졸업한 후
                <br/>다양한 가수들과 함께 
                <br/>공연 및 연주 활동을 하고 있습니다. 
                <br/>
                <br/>명문대 출신으로서, 탄탄한 배경과 
                <br/>다채로운 공연 경험을 통해 
                <br/>쌓아온 노하우를 바탕으로 재미있고 
                <br/>수준 높은 레슨을 약속드립니다.
                <br/>
                <br/>여러분의 음악적 여정을 
                <br/>더욱 멋지게 만들어 드리겠습니다. 
               
                
            </div>
            <div>
            <img id='hamin' src={hamin} alt='hamin'></img>
            </div>
            <div>
                <h3>프로필</h3>
                <div className='profileBox'>
                    <div className='pro'>
                        <img className='profile' alt='img' src={img1}></img>
                        <p>가수 장윤정 세션</p>
                    </div>
                    <div className='pro'>
                        <img className='profile' alt='img' src={img2}></img>
                        <p>가수 장민호 세션</p>
                    </div>
                    <div className='pro'>
                        <img className='profile' alt='img' src={img3}></img>
                        <p>가수 송가인 세션</p>
                    </div>
                </div>
                <div className='profileBox'>
                <div className='pro'>
                        <img className='profile' alt='img' src={img10}></img>
                        <p>미스트롯3 전국투어 세션</p>
                    </div>
                    <div className='pro'>
                        <img className='profile' alt='img' src={img4}></img>
                        <p>불후의 명곡 세션</p>
                        <p>(정훈희, 라포엠, 김태우)</p>
                    </div>
                 
                    <div className='pro'>
                        <img className='profile' alt='img' src={img6}></img>
                        <p>가수 김현철 세션</p>
                    </div>
                </div>
                <div className='profileBox'>
                    <div className='pro'>
                        <img className='profile' alt='img' src={img7}></img>
                        <p>뮤지컬 캣츠 40주년 투어</p>
                     
                     
                    </div>
                    <div className='pro'>
                        <img className='profile' alt='img' src={img8}></img>
                      
                        <p>뮤지컬 캣츠 대만 투어</p>
                     
                    </div>
                    <div className='pro'>
                        <img className='profile' alt='img' src={img9}></img>
                        <p>저스틴 허위츠 내한<br/> 라라랜드 콘서트</p>
                    </div>
                  
                </div>
                <div className='profileBox'>
                <div className='pro'>
                        <img className='profile' alt='img' src={img5}></img>
                        <p>유희열의 스케치북 세션</p>
                    </div>
                    <div className='pro'>
                        <img className='profile' alt='img' src={img11}></img>
                        <p>박경 콘서트 세션</p>
                    </div>
                </div>
                
              
              
                
            </div>
        </Div>
    );
};

export default Director;