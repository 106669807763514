import React from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import '../../App.css';
import Wrapper from '../common/Wrapper';
import DivFooter from '../common/DivFooter';
import styled from 'styled-components';
import img1 from '../img/InfoStudioImg/directorRoo4.jpg';
import img2 from '../img/InfoStudioImg/directorRoom.jpg';
import img3 from '../img/InfoStudioImg/directorRoom3.jpg';
import img4 from '../img/coffeeRoomImg/coffeeRoomImg1.jpg';
import img5 from  '../img/coffeeRoomImg/coffeeRoomImg3.jpg';
import img6 from  '../img/coffeeRoomImg/coffeeRoomImg4.jpg';
import img7 from  '../img/hallImg/hall1.jpg';
import img8 from  '../img/hallImg/hall2.jpg';
import img9 from  '../img/hallImg/hall3.jpg';
import img10 from  '../img/trainingRoomImg/trainingImg2.jpg';
import img11 from  '../img/trainingRoomImg/trainingImg3.jpg';
import img12 from  '../img/trainingRoomImg/trainingImg4.jpg';
import Empty from '../common/Empty';




const Content = styled.div`
width:90vw;
margin: 0 auto;
p{
  color: #454545;
  line-height:1.5;
}
/* 미디어 쿼리: 화면 가로폭이 768px 미만일 때 */
@media screen and (max-width: 768px) {
  
    img{  -webkit-user-drag:none;
        width: 100%;
        margin: 10px 0 ;
        border-radius:20px;
      }
      display:flex;
      flex-direction:column;
      align-items:center;
      .imgList{
        display:flex;
        flex-direction:column;
        margin:0 10px;
      }
      h3{
        margin-top:50px;
      }
  
}

/* 미디어 쿼리: 화면 가로폭이 768px 이상일 때 */
@media screen and (min-width: 768px) {
    img{  -webkit-user-drag:none;
        height:450px;
        width:350px;
        margin: 0 20px;
        border-radius:20px;
      }
      display:flex;
      flex-direction:column;
      align-items:center;
      .imgList{
        display:flex;
        margin:10px 0;
      }
      h3{
        margin-top:50px;
      }
}

`
const FacilityPage = () => {
    return (
        <Wrapper>
            <DivFooter>
            <Header/>
            <Empty/>
          
            <Content>
              <p>* 학원 내에 락커, 신발장,  CCTV</p>
              <p>* 휴게실 정수기, 커피, 차 구비</p>
              <p>* 공기청정기 상시 가동</p>
                <h3>레슨실</h3>
                <div className='imgList'>
                <img alt='facility' src={img1}/>
                <img alt='facility' src={img2}/>
                <img alt='facility' src={img3}/>
                </div>
                <h3>휴게실</h3>
                <div className='imgList'>
                <img alt='facility' src={img4}/>
                <img alt='facility' src={img5}/>
                <img alt='facility' src={img6}/>
                </div>
                <h3>무대 & 홀</h3>
                <div className='imgList'>
                <img alt='facility' src={img7}/>
                <img alt='facility' src={img8}/>
                <img alt='facility' src={img9}/>
                </div>
                <h3>연습실</h3>
                <div className='imgList'>
                <img alt='facility' src={img10}/>
                <img alt='facility' src={img11}/>
                <img alt='facility' src={img12}/>
                </div>
            </Content>
   
            <Footer/>
            </DivFooter>
        </Wrapper>
    );
};

export default FacilityPage;