import React from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Wrapper from '../common/Wrapper';
import DivFooter from '../common/DivFooter';
import Write from '../news/Write';
import Empty from '../common/Empty';

const WritePage = () => {
    return (
        <Wrapper>
        <DivFooter>
           <Header/>
           <Empty/>
           <Write/>
           </DivFooter>
       <Empty/>

   </Wrapper>
    );
};

export default WritePage;