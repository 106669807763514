import React from 'react';
import styled from 'styled-components';
import palette from '../styles/palette';
const StyledButton = styled.button`
margin: 0 10px;
width:100px;
height:25px;
background-color: ${palette.Gray[2]};
border:none;
border-radius:10px;
cursor:pointer;
box-shadow:5px 5px 5px -6px black;
transition: box-shadow 1s ease;
&:hover{
    box-shadow:inset 1px 1px 3px #454545;

}
`

const Button = (props) => {
    return (
        <StyledButton {...props}></StyledButton>
    );
};

export default Button;