import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
const FooterDiv = styled.div`

/* 미디어 쿼리: 화면 가로폭이 768px 미만일 때 */
@media screen and (max-width: 768px) {
   display:flex;
   flex-direction:column;
   align-items:center;
    text-align:center;
    height:190px;
}

/* 미디어 쿼리: 화면 가로폭이 768px 이상일 때 */
@media screen and (min-width: 768px) {
    text-align:left;
    height:160px;
    #footer-rule{
        display:flex;
        flex-direction:row;
    }
    #footer-rule div{
        margin: 0 10px;
    }

}
    padding:20px;
    background-color:#ccc;
    display:flex;
    width:100vw;
    justify-content:space-around;
    color:#454545;
    font-size:12px;
    line-height:2;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    a{
        text-decoration:none;
        color:#454545;
    }
`

const Footer = () => {
    return (
        <FooterDiv id='footer-div'>
            <div id='footer-info'>
                <div>이하민 색소폰 스튜디오</div>
                <div>Address. 경기도 안산시 단원구 이삭로 8 4층 402호</div>
                <div>Tel. 010-5505-9092</div>
                <div>사업자 등록번호. 148-67-00644</div>
                <div>Copyright ⓒ 2024 이하민색소폰스튜디오 All rights reserved.</div>
            </div>
            <div id='footer-rule'>
                <div><Link to='/use'>이용약관</Link></div>
                <div><Link to='/person'>개인정보취급방침</Link></div>
            </div>
        </FooterDiv>
    );
};

export default Footer;