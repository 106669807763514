import React, { useEffect } from 'react';
import styled from 'styled-components';
import palette from './styles/palette';

const {kakao} = window;

const Div = styled.div`

/* 미디어 쿼리: 화면 가로폭이 768px 미만일 때 */
@media screen and (max-width: 768px) {
    #empty{
        width:100vw;
        height:200px;
    }
   
    .mapDiv{
        display:flex;
        background-color:${palette.Gray[2]};
        border-radius:20px;
        flex-direction:column;
        width:90vw;
        margin:0 auto;
    
    }
   width:100vw;
   #map{
    border-radius:20px;
    width:90vw;
    height:50vh;
    margin:0 auto;
}
.mapInfo{
    color:black;
    width:90vw;
    margin:0 auto;
    padding:1rem;
    line-height:2;
    align-content:center;
    text-align:center;

}
}

/* 미디어 쿼리: 화면 가로폭이 768px 이상일 때 */
@media screen and (min-width: 768px) {
    #empty{
        width:100vw;
        height:400px;
    }
    @keyframes moveleft {
        0% {
            opacity: 0;
            transform: translateX(350px);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
    .mapDiv{
        display:flex;
        background-color:${palette.Gray[2]};
        border-radius:20px;
    }
    width:900px;
    margin:0 auto;
    #map{
        border-radius:20px;
        animation:moveleft 1s;
        width:600px;
        height:400px;
    }
    .mapInfo{
        color:black;
        width:300px;
        padding-left:1rem;
        line-height:2;
        align-content:center;
    
    }
  
    
}



h2{
    text-align:center;
    margin-bottom:3rem;
}



`


const KakaoMap = () => {

    useEffect(()=>{
        var geocoder = new kakao.maps.services.Geocoder();

        var callback = function(result, status) {
            if (status === kakao.maps.services.Status.OK) {
                console.log(result);
            }
        };
        
        geocoder.addressSearch('경기 안산시 단원구 이삭로 8', callback);


        const container = document.getElementById('map');
        const options = {
            center: new kakao.maps.LatLng(37.3140660690919, 126.837404148429),
            level:3
        };
        const map = new kakao.maps.Map(container, options);
    
        var marker = new kakao.maps.Marker({
            map: map,
            position: new kakao.maps.LatLng(37.3140660690919, 126.837404148429)
          });
    
          // 인포윈도우로 장소에 대한 설명을 표시합니다
          var infowindow = new kakao.maps.InfoWindow({
            content: '<div style="width:150px;color:black;text-align:center;padding:6px 0;font-size:0.8rem;">이하민 색소폰 스튜디오</div>'
          });
          infowindow.open(map, marker);
    },[])
   
 
    return (
        <Div>
            <h2>오시는길</h2>
            <div className='mapDiv'>
                <div>
                    <div id='map'>     
                    </div>
                </div>
         
                <div className='mapInfo'>
                    <p>경기 안산시 단원구 이삭로 8</p>
                    <p>동현빌딩 4층</p>
                    <p>중앙역 2번 출구에서 365m</p>
                    <p>건물 뒷편 주차 가능</p>
                    <p>연중무휴 24시간 운영</p>
                    <p>문의 010-5505-9092</p>

                </div>

            </div>
            <div id='empty'>

            </div>
        </Div>
    );
};

export default KakaoMap;