import React from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Wrapper from '../common/Wrapper';
import DivFooter from '../common/DivFooter';
import Post from '../news/Post';
import Empty from '../common/Empty';

const PostPage = () => {
    return (
        <Wrapper>
        <DivFooter>
           <Header/>
       <Empty/>
           <Post/>
           <Empty/>
           </DivFooter>
    
   </Wrapper>
    );
};

export default PostPage;