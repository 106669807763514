import React from 'react';
import Wrapper from '../common/Wrapper';
import DivFooter from '../common/DivFooter';
import Header from '../common/Header';
import Empty from '../common/Empty';
import Footer from '../common/Footer';
import LessonForm from '../manage/LessonForm';
const ManageLessonPage = () => {
    return (
        <Wrapper>
        <DivFooter>
        <Header/>
        <Empty/>
        <LessonForm/>
  <Empty/>
        </DivFooter>

    </Wrapper>
    );
};

export default ManageLessonPage;