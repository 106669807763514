import React from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Wrapper from '../common/Wrapper';
import JoinForm from '../login/JoinForm';
import Empty from '../common/Empty';
import styled from 'styled-components';
import DivFooter from '../common/DivFooter';


const JoinPage = () => {
    return (
        <Wrapper>
             <DivFooter>
            <Header/>
            <Empty/>
            <JoinForm/>
    
            </DivFooter>
            <Footer/>
         
        </Wrapper>
    );
};

export default JoinPage;