import React from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Wrapper from '../common/Wrapper';
import DivFooter from '../common/DivFooter';
import KaKaoMap from '../KakaoMap';
import Empty from '../common/Empty';
const AddressPage = () => {
    return (
        <Wrapper>
            <DivFooter>
            <Header/>
            <Empty/>
            <KaKaoMap/>
            <Footer/>
            </DivFooter>
          
        </Wrapper>
    );
};

export default AddressPage;