import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";

const FormDiv = styled.form`
  width: 300px;
  height: 200px;
  background-color: #ccc;
  position: fixed;
  left: 35%;
  top: 30%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

const Modal = ({
  visible,
  setVisible,
  selectedStudentName,
  handleLessonCharge,
}) => {
  const localID = localStorage.getItem("id");
  const [userId, setUserId] = useState(localID);
  const [paymentAmount, setPaymentAmount] = useState("");
  const [lessonCount, setLessonCount] = useState("");

  const handlePaymentAmountChange = (e) => {
    setPaymentAmount(e.target.value);
  };

  const handleLessonCountChange = (e) => {
    setLessonCount(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUserId(localID);
    try {
      //결제 하기
      await axios.post("http://43.201.52.51:5001/api/payList", {
        userId: userId, // userId 포함,
        name: selectedStudentName,
        pay: paymentAmount,
      });
      handleLessonCharge(selectedStudentName, lessonCount, userId);
      setVisible(false);
      alert(
        `${selectedStudentName} 님이 ${lessonCount}회 충전 ${paymentAmount} 원을 결제 하셨습니다.`
      );
    } catch (error) {
      console.error("Error:", error.response.data.error);
    }
  };

  const cancelmodal = () => {
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <FormDiv onSubmit={handleSubmit}>
      <h3>{selectedStudentName} 님</h3>
      <p>
        결제 비용 :{" "}
        <input
          type="text"
          placeholder="결제 비용"
          value={paymentAmount}
          onChange={handlePaymentAmountChange}
        />
      </p>
      <p>
        레슨 충전 수 :{" "}
        <input
          type="text"
          placeholder="충전 수"
          value={lessonCount}
          onChange={handleLessonCountChange}
        />
      </p>
      <div>
        <button type="submit">확인</button>
        <button onClick={cancelmodal}>취소</button>
      </div>
    </FormDiv>
  );
};

export default Modal;
