import React from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Wrapper from '../common/Wrapper';
import DivFooter from '../common/DivFooter';
import styled from 'styled-components';

const Div= styled.div`
width:90vw;
margin:0 auto;
text-align:center;
textarea{
    width:70vw;
    height:200px;
    margin-bottom:300px;
    margin-top:100px;
}
`

const PersonPage = () => {
    return (
        <Wrapper>
        <DivFooter>
        <Header/>
        </DivFooter>
        <Div>
        <h2>개인정보취급방침</h2>
        <textarea>
        개인정보의 처리 및 보유기간
① 이하민색소폰 스튜디오 학원은(는) 법령에 따른 개인정보 보유・이용기간 또는 정보주체로부터
개인정보를 수집 시에 동의 받은 개인정보 보유・이용기간 내에서 개인정보를
처리・보유합니다.
② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
 1. 홈페이지 회원 가입 및 관리 : 법령이 정하는 경우를 제외하고는 회원 탈퇴 시
까지 보유 및 이용
 2. 학원 현장 방문 상담・테스트 : 테스트 종료 후 지체없이 파기
 3. 기록 보관 :
 - 영수증 원본 : 5년(학원의 설립・운영 및 과외교습에 관한 법률 시행규칙
제16조)
 - 수강생 대장 : 3년(학원의 설립・운영 및 과외교습에 관한 법률 시행규칙
제16조)
③ 다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지 보관합니다.
 1. 관계 법령 위반에 따른 수사・조사 등이 진행 중인 경우에는 해당 수사・조사
종료 시까지
 2. 예외 사유 시에는 보유기간까지
  
        </textarea>
        </Div>
        <Footer/>
    </Wrapper>
    );
};

export default PersonPage;