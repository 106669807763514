import React from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Wrapper from '../common/Wrapper';
import DivFooter from '../common/DivFooter';
import Director from '../Director';
import Empty from '../common/Empty';



const DirectorPage = () => {
    return (
    <Wrapper>
         <DivFooter>
            <Header/>
            <Empty/>
            <Director/>
     
        <Footer/>
        </DivFooter>
    </Wrapper>
    );
};

export default DirectorPage;