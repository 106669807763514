import React from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Wrapper from '../common/Wrapper';
import DivFooter from '../common/DivFooter';
import Curriculum from '../Curriculum';
import Empty from '../common/Empty';

const CurriculumPage = () => {
    return (
        <Wrapper>
         <DivFooter>
            <Header/>
            <Empty/>
            <Curriculum/>
        
            <Footer/>
            </DivFooter>
        </Wrapper>
    );
};

export default CurriculumPage;