import React from 'react';
import Wrapper from '../common/Wrapper';
import DivFooter from '../common/DivFooter';
import Header from '../common/Header';
import Empty from '../common/Empty';
import Footer from '../common/Footer';
import MemberDetail from '../manage/MemberDetail';
const MemberDetailPage = () => {
    return (
        <Wrapper>
        <DivFooter>
        <Header/>
        <Empty/>
       <MemberDetail/>

        </DivFooter>
     
    </Wrapper>
    );
};

export default MemberDetailPage;