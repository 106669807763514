import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import palette from '../styles/palette';
const StyledLink = styled(Link)`
margin: 0 10px;
width:70px;
height:25px;
background-color: ${palette.Gray[2]};
border:none;
border-radius:10px;
cursor:pointer;
box-shadow:5px 5px 5px -6px black;
transition: box-shadow 1s ease;
&:hover{
    box-shadow:inset 1px 1px 3px #454545;

}
text-decoration:none;
color: black;
font-size: 13px;
align-content:center;
text-align:center;
`

const LinkButton = (props) => {
    return (
        <StyledLink {...props}>
            
        </StyledLink>
    );
};

export default LinkButton;