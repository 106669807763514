import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import Button from "../common/Button";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const Div = styled.div`
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    h2 {
      text-align: center;
    }
    width: 100vw;
    #title {
      text-indent: 10px;
      width: 100vw;
      line-height: 2;
      font-size: 24px;
      outline: none;
      border-radius: 20px;
      border: 1px solid #ccc;
      margin-top: 10px;
    }
    #btns {
      margin-top: 80px;
      margin-bottom: 40px;
      display: flex;
      justify-content: center;
    }
    .btn {
      height: 35px;
      font-size: 18px;
    }
  }

  @media screen and (min-width: 768px) {
    width: 700px;
    margin: 0 auto;
    #title {
      line-height: 2;
      font-size: 24px;
      outline: none;
      border: 1px solid #ccc;
      border-radius: 20px;
      margin-top: 2rem;
      width: 100%;
      text-indent: 10px;
    }
    .btn {
      margin: 50px auto;
      height: 30px;
      font-size: 16px;
    }
    #btns {
      display: flex;
    }
  }
`;

const Write = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const quillRef = useRef();
  const postId = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const auth = localStorage.getItem("id");
    setId(auth);
    if (postId.postId) {
      const fetchPost = async () => {
        try {
          const response = await axios.get(
            `http://43.201.52.51:5001/api/posts/${Number(postId.postId)}`
          );
          setTitle(response.data.title);
          setContent(response.data.content);
        } catch (error) {
          console.error("Error fetching post: ", error);
        }
      };
      fetchPost();
    }
  }, [postId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = { id, title, content };
    try {
      if (postId.postId) {
        await axios.put(
          `http://43.201.52.51:5001/api/update/${Number(postId.postId)}`,
          postData
        );
        navigate("/news");
      } else {
        await axios.post("http://43.201.52.51:5001/api/write", postData);
        navigate("/news");
      }
    } catch (error) {
      console.error("Error saving post:", error);
    }
  };

  const handleImageUpload = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (file) {
        setLoading(true);
        const formData = new FormData();
        formData.append("image", file);

        try {
          const response = await axios.post(
            "http://43.201.52.51:5001/api/img",
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );

          if (response.status !== 200) {
            throw new Error("이미지 업로드 실패");
          }

          const imageUrl = response.data.url; // 서버에서 반환된 이미지 URL
          const quill = quillRef.current.getEditor();
          const range = quill.getSelection();
          quill.insertEmbed(range.index, "image", imageUrl);

          setLoading(false);
          setError(null);
        } catch (error) {
          console.error("Error uploading image:", error);
          setLoading(false);
          setError("이미지 업로드 중 오류가 발생했습니다.");
        }
      }
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: "1" }, { header: "2" }],
          [{ size: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
          ["image"],
        ],
        handlers: { image: handleImageUpload },
      },
    }),
    []
  );

  const onCancel = (e) => {
    e.preventDefault();
    navigate("/news");
  };

  return (
    <Div>
      <h2>학원 소식 등록하기</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          id="title"
          placeholder="제목을 입력해주세요."
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <br />
        <br />
        <ReactQuill
          theme="snow"
          value={content}
          placeholder="내용을 입력해주세요."
          onChange={setContent}
          style={{ height: "300px", marginBottom: "20px" }}
          modules={modules}
          formats={[
            "header",
            "font",
            "size",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
            "video",
            "color",
            "background",
          ]}
          ref={quillRef}
        />
        <br />
        <div id="btns">
          <Button className="btn" type="submit">
            등록하기
          </Button>
          <Button className="btn" onClick={onCancel}>
            취소
          </Button>
        </div>
      </form>
      {loading && <p>이미지 업로드 중...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </Div>
  );
};

export default Write;
