import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  position: fixed;
  width: 100vw;

  align-items: end;
  background-color: #fff;
  z-index: 999;
  /* 미디어 쿼리: 화면 가로폭이 400px 미만일 때 */
  @media screen and (max-width: 400px) {
    #join {
      display: none;
    }
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 180px;
    text-align: center;
    #menuList3 {
      position: absolute;
      right: 10px;
      font-size: 9px;
      top: 15px;
    }
    a {
      margin: 3px 0;
    }
    .menu a {
      font-size: 12.5px;
    }
    #head-logo {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 10px;
    }
    #menuList1 {
      font-size: 10px;
    }
    .menu {
      display: flex;
      flex-direction: row; /* 수평으로 표시 */
    }
  }

  /* 미디어 쿼리: 화면 가로폭이 768px 미만일 때 */
  @media screen and (max-width: 768px) {
    #join {
      display: none;
    }
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 180px;
    text-align: center;
    #menuList3 {
      position: absolute;
      right: 10px;
      font-size: 10px;
      top: 15px;
    }
    a {
      margin: 3px 0;
    }
    #head-logo {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 10px;
    }
    #menuList1 {
      font-size: 14px;
    }
    .menu {
      display: flex;
      flex-direction: row; /* 수평으로 표시 */
    }
  }

  /* 미디어 쿼리: 화면 가로폭이 768px 이상일 때 */
  @media screen and (min-width: 768px) {
    .menu {
      display: flex;
      flex-direction: row; /* 수평으로 표시 */
    }
    #head-logo {
      font-size: 20px;
      font-weight: bold;
    }
    height: 80px;
  }

  .menu div {
    margin: 0 10px;
  }

  a {
    -webkit-user-drag: none;
    text-decoration: none;
    color: black;
    &:hover {
      opacity: 0.5;
      transition: ease-in-out 0.3s;
    }
  }
  #head-logo {
    color: #ff8c00;
  }
`;

const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navi = useNavigate();
  // 로컬 스토리지에서 데이터 가져오기
  const auth = localStorage.getItem("id");
  const LogoutButton = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("id");
    navi("/");
    // 원하는 작업 수행
    const handleLogout = async () => {
      try {
        const response = await axios.post(
          "http://43.201.52.51:5001/api/user/logout"
        );
        console.log(response.data.message);
        // 로그아웃 성공 시 추가적으로 수행할 작업을 여기에 작성합니다.
        // 로그아웃 시 로컬 스토리지에서 데이터 삭제
      } catch (error) {
        console.error("Error logging out:", error);
        // 로그아웃 실패 시 에러 처리를 여기에 작성합니다.
      }
    };
  };
  // 페이지가 로드될 때 로그인 상태 확인
  useEffect(() => {
    const data = localStorage.getItem("isLoggedIn");
    setIsLoggedIn(data);
  }, [isLoggedIn, auth]);
  return (
    <HeaderDiv id="menu-container">
      <Link to="/">
        <div id="head-logo">
          Lee Hamin
          <br />
          Saxophone Studio
        </div>
      </Link>

      <div className="menu" id="menuList1">
        <Link to="/director">
          <div>원장 소개</div>
        </Link>
        <Link to="/facility">
          {" "}
          <div>시설 안내</div>
        </Link>
        <Link to="/news">
          <div>학원 소식</div>
        </Link>
        <Link to="/curriculum">
          <div>교육 과정</div>
        </Link>
        <Link to="/address">
          <div>오시는길</div>
        </Link>
      </div>

      {isLoggedIn ? (
        <div className="menu" id="menuList2">
          <Link to="/managemember">
            <div>학원생 관리</div>
          </Link>
          <Link to="/managelesson">
            {" "}
            <div>레슨 관리</div>
          </Link>
          <Link to="/lessontime">
            {" "}
            <div>시간표</div>
          </Link>
        </div>
      ) : (
        ""
      )}
      {!isLoggedIn ? (
        <div className="menu" id="menuList3">
          <Link to="/login">
            <div>로그인</div>
          </Link>
          <Link to="/join">
            {" "}
            <div id="join">회원가입</div>
          </Link>
        </div>
      ) : (
        <div className="menu" id="menuList4">
          <div>{auth}님 환영합니다.</div>
          <Link to="/">
            {" "}
            <div onClick={LogoutButton}>로그아웃</div>
          </Link>
        </div>
      )}
    </HeaderDiv>
  );
};

export default Header;
