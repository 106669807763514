import React from 'react';
import styled from 'styled-components';
const Div =styled.div`
min-height: 100%;
position: relative;
width:100vw;

/* 미디어 쿼리: 화면 가로폭이 768px 미만일 때 */
@media screen and (max-width: 768px) {
    padding-bottom:190px;
}

/* 미디어 쿼리: 화면 가로폭이 768px 이상일 때 */
@media screen and (min-width: 768px) {
    padding-bottom:160px;

}
`
const DivFooter = ({children}) => {
    return (
        <Div>
            {children}
        </Div>
    );
};

export default DivFooter;