import React from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Wrapper from '../common/Wrapper';
import LoginForm from '../login/LoginForm';
import Empty from '../common/Empty';
import DivFooter from '../common/DivFooter';
const LoginPage = () => {
    return (
        <Wrapper>
            <DivFooter>
            <Header/>
            <Empty/>
            <LoginForm/>
            </DivFooter>
            <Footer/>
        </Wrapper>
    );
};

export default LoginPage;