import React from 'react';
import styled from 'styled-components';
import palette from './styles/palette';

const Div = styled.div`
/* 미디어 쿼리: 화면 가로폭이 768px 미만일 때 */
@media screen and (max-width: 768px) {
    width:80vw;   
    div{
        font-size:15px;
    }
   
}

/* 미디어 쿼리: 화면 가로폭이 768px 이상일 때 */
@media screen and (min-width: 768px) {
    width:700px;
  
}
   

margin: 0 auto;
text-align: center;
line-height:2;
h3{font-weight: bold;
    margin-top:50px;
}
#main{
    background-color:${palette.Gray[2]};
    border-radius: 20px;
    padding:20px 0;
}
`
const Curriculum = () => {
    return (
        <Div>
            <div id='main'>
            지하층 낙후된 색소폰 동호회 & 학원은
            <br/> 이제 그만!
            <br/>퀄리티 있는 공간에서 
            <br/>당신의 색소폰 라이프를 시작하세요.
            </div>
             <div>
                <h3>레슨이 필요하신 분</h3>
                <div>
                    색소폰 다년차, 실력이 멈추신 분
                    <br/>박자를 모르시는 분
                    <br/>단기간에 실력을 늘리고 싶으신 분
                    <br/>가요 맛내기 안 되시는 분
                    <br/>고음에서 소리가 답답하신 분
                    <br/>저음 소리가 잘 안나시는 분
                    <br/>계이름 읽는게 어려우신 분
                    <br/>삑사리 때문에 힘드신 분
                    <br/>애드립이 궁금하신 분
                    <br/>서브톤,밴딩,드랍,비브라토 등 궁금하신 분
                    <br/>톤이 안 좋아서 고민이신 분

                    <br/><br/>모든 것엔 안되는 이유가 무조건 있습니다.
                    <br/>혼자 스트레스 받지 마세요.
                    <br/>모든 답을 다 알려드립니다.
                </div>
            </div>
            <div>
                <h3>수업 과정</h3>
                <div>
                    레슨과 연습실까지 사용가능합니다.
                    <br/> 분기별 향상음악회를 개최합니다.
                    <br/> 연주곡 녹음 및 영상 촬영을 무제한 제공합니다.
                    <br/> 연습실은 24시간 365일 사용 가능합니다. 
                    <br/> 분기별로 외부 버스킹 공연을 진행합니다.
                    <br/> * 연습실만 사용은 불가합니다.
                    <br/>
                    <br/> 색소폰 대회반 모집
                    <br/> 입문 & 초보반 모집
                    <br/> 입시반 모집
                 
                </div>
            </div>
            <div>
                <h3>수강료 안내</h3>
                <p>주 1회 1시간 4회 20만원</p>
                
            </div>
            <div>
                <h3>규정 및 환불 안내</h3>
                <div>
                    수업 1/2회 이상 진행시 환불 불가합니다.
                    <br/>수업 무단결석 & 지각 시에 보강 및 환불 불가합니다.
                    <br/>개인 사정상 결석시에 최소 2일전 말씀해 주시면 보강 가능합니다.
                    <br/>*사전 합의 결석 시 보강 가능 횟수는 월 1회까지만 가능합니다.
                </div>
            </div>
            <h3>문의 010 5505 9092</h3>
        </Div>
    );
};

export default Curriculum;