import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
width:100%;

/* 미디어 쿼리: 화면 가로폭이 768px 미만일 때 */
@media screen and (max-width: 768px) {
    height: 250px;
}

/* 미디어 쿼리: 화면 가로폭이 768px 이상일 때 */
@media screen and (min-width: 768px) {
    height:200px;

}

`
const Empty = () => {
    return (
        <Div></Div>
    );
};

export default Empty;