import React from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Wrapper from '../common/Wrapper';
import DivFooter from '../common/DivFooter';
import styled from 'styled-components';
import SlideShow from '../SlideShow';
import Empty from '../common/Empty';



const MainPage = () => {
    return (
        <Wrapper>
          <DivFooter>
            <Header/>
            <Empty/>
            <SlideShow/>
            <Footer/>
            </DivFooter>
        
       
        </Wrapper>
    );
};

export default MainPage;