import React from 'react';
import Wrapper from '../common/Wrapper';
import DivFooter from '../common/DivFooter';
import Header from '../common/Header';
import Empty from '../common/Empty';
import MemberForm from '../manage/MemberForm';
import Footer from '../common/Footer';
const ManageMemberPage = () => {
    return (
        <Wrapper>
        <DivFooter>
        <Header/>
        <Empty/>
        <MemberForm/>
   <Empty/>
        </DivFooter>
     
    </Wrapper>
    );
};

export default ManageMemberPage;