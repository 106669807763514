import React, { useRef } from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../common/Button";
import { Link } from "react-router-dom";

const Div = styled.div`
  /* 미디어 쿼리: 화면 가로폭이 768px 미만일 때 */
  @media screen and (max-width: 768px) {
    width: 100vw;
    h2 {
      text-align: center;
    }
    button {
      width: 90px;
      height: 40px;
      font-size: 17px;
    }
    img {
      width: 130px;
    }
  }

  /* 미디어 쿼리: 화면 가로폭이 768px 이상일 때 */
  @media screen and (min-width: 768px) {
    width: 80vw;
    margin: 0 auto;
    .img {
      width: 200px;
    }
  }

  tr,
  th,
  td {
    border-top: 1px solid #ccc;
  }
  th {
    height: 40px;
  }
  .title {
    width: 350px;
    text-align: center;
    border-right: 1px solid #ccc;
    height: 30px;
  }
  .writeDay {
    width: 150px;
    text-align: center;
    height: 30px;
    font-size: 12px;
  }
  .id {
    width: 100px;
    text-align: center;
    height: 30px;
  }
  a {
    text-decoration: none;
    color: black;
  }
  button {
    float: right;
  }
  table {
    margin: 50px auto;
    border-bottom: 1px solid #ccc;
  }
`;
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const NewsList = () => {
  const [posts, setPosts] = useState([]);
  const auth = localStorage.getItem("id");

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(
          "http://43.201.52.51:5001/api/newsList"
        );
        setPosts(response.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, []);

  const extractImageFromContent = (content) => {
    // HTML 문자열에서 이미지 태그를 추출
    const imgRegex = /<img[^>]+src="([^">]+)"/;
    const match = content.match(imgRegex);
    return match ? match[1] : null; // 이미지 URL 반환
  };
  const extractTextFromContent = (content) => {
    // HTML 문자열에서 태그를 제거하여 순수한 텍스트만 추출
    const text = content.replace(/<[^>]*>/g, "");
    // 문자열이 400자를 초과하는 경우 줄임표(...) 추가
    return text.length > 400 ? text.slice(0, 200) + "..." : text;
  };

  return (
    <Div>
      <h2>학원 소식</h2>
      {auth ? (
        <Button>
          <Link to="/write">글 쓰기</Link>
        </Button>
      ) : (
        ""
      )}
      <table>
        <tr>
          <th colSpan="2" className="title">
            제목
          </th>
          <th className="writeDay">작성일</th>
        </tr>
        {posts.map((post) => (
          <tr key={post.index}>
            <td>
              {" "}
              <div className="image">
                {extractImageFromContent(post.content) && (
                  <Link to={`/post/${post.postId}`}>
                    <img
                      className="img"
                      src={extractImageFromContent(post.content)}
                      alt="이미지"
                    />
                  </Link>
                )}
              </div>
            </td>
            <td className="title">
              <Link to={`/post/${post.postId}`}>{post.title}</Link>
            </td>
            <td className="writeDay">{formatDate(post.writeDay)}</td>
          </tr>
        ))}
      </table>
    </Div>
  );
};

export default NewsList;
