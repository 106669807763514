import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import main1 from './img/mainImg/studio1.jpg'
import main2 from './img/mainImg/studio2.jpg'
import main3 from './img/mainImg/studio3.jpg'
import main4 from './img/mainImg/studio4.jpg'
import main5 from './img/mainImg/studio5.jpg'
import main6 from './img/mainImg/studio6.jpg'
import main7 from './img/mainImg/studio7.jpg'
import main8 from './img/mainImg/studio8.jpg'
import styled from 'styled-components';

// 슬라이드에 표시할 이미지들의 배열
const images = [
  main1, main2, main3, main4, main5, main6,main7,main8
];

const Div = styled.div`

/* 미디어 쿼리: 화면 가로폭이 768px 미만일 때 */
@media screen and (max-width: 768px) {
    margin: 0 auto;
    
    width:100vw;
    display:flex;
    flex-direction:column;
    align-items:center;
    img{  
      -webkit-user-drag:none;
        width:100vw;
    }
    .slide{
        display:none;
    }
    h2{   
      width:90vw;
        margin-bottom:100px;
        text-align:center;
        color:#ac4e27;
        text-shadow: 5px 5px 5px #ccc;
        font-size:18px;
    }

}

/* 미디어 쿼리: 화면 가로폭이 768px 이상일 때 */
@media screen and (min-width: 768px) {
  #empty{
    width:100vw;
    height:300px;
  }
margin: 0 auto;

width:100vw;
img{  -webkit-user-drag:none;
    width:400px;
    height:550px;
    margin: 0 auto;
}
h2{

    margin-bottom:50px;
    text-align:center;
    color:#ac4e27;
    text-shadow: 5px 5px 5px #ccc;
}
.nomal{
    display:none;
}
.slide{
  margin:0 auto;
  width: 1300px;
}

`
const SlideShow = () => {
  const settings = {
    dots: true, // 점 표시
    infinite: true, // 무한 루프
    speed: 2500, // 슬라이드 전환 속도
    slidesToShow: 3, // 보여질 슬라이드 개수
    slidesToScroll: 1, // 넘어가는 슬라이드 개수
    autoplay: true, // 자동 재생
    autoplaySpeed: 0, // 자동 재생 속도 (밀리초 단위)
  };

  return (
    <Div>
      <h2>당신이 색소폰 라이프를 즐길 수 있는 곳.</h2>
      <div className='slide'>
      <Slider className='slide' {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt='main' />
          </div>
        ))}
      </Slider>
      </div>
      {images.map((image, index) => (
          <div className='nomal' key={index}>
            <img className='nomal' src={image} alt='main' />
          </div>
      ))
}
<div id='empty'>

</div>
    </Div>
  );
};

export default SlideShow;